<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, i) in this.$store.state.qna.ranking"
        v-if="i < defaultThree"
        :key="i"
        cols="12"
        xl="3"
        lg="4"
        md="4"
        sm="4"
      >
        <v-card
          height="100%"
          elevation="6"
          @click="goToTest(item.id)"
        >
          <v-card-text>
            <v-img
              class="text-h5"
              aspect-ratio="1"
              max-height="400"
              max-width="400"
              :src="item.thumburl"
            />
          </v-card-text>

          <v-card-title
            class="text-h5 ma-1 pa-2 pt-4"
            v-text="item.title"
          />
          <v-card-subtitle class="text-subtitle-1 ma-1 pa-2 pt-1 pb-3">
            <strong>{{ item.count }}</strong
            >명이 이 테스트를 해봤어요!
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              text
              class="text-subtitle-1 ma-1 pa-0"
              color="teal primary-8"
            >
              테스트 하기
              <v-icon> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <infinite-loading
      v-show="this.$store.state.qna.ranking.length > 0"
      spinner="bubbles"
      @infinite="infiniteHandler"
    />
  </v-container>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      testRankingData: this.$store.state.qna.ranking,
      thumbnail: '',
      page: 1,
      defaultThree: 3,
    }
  },
  async beforeMount() {
    this.defaultThree = 3
    if (this.testRankingData.length == 0) {
      await this.$store.dispatch('qna/fetchRanking')
    }
  },
  mounted() {
    this.defaultThree = 3
  },
  methods: {
    goToTest(themeId) {
      this.$router.push('/test/' + themeId)
    },
    infiniteHandler($state) {
      if (this.$store.state.qna.ranking.length > this.defaultThree) {
        for (let i = 0; i < 3; i++) {
          if (this.$store.state.qna.ranking.length == this.defaultThree) {
            setTimeout(() => {
              $state.loaded()
            }, 1500)
            return
          }
          this.page += 1
          this.defaultThree += 1
        }
        setTimeout(() => {
          $state.loaded()
        }, 1500)
      } else {
        $state.complete()
      }
    },
  },
}
</script>

<style></style>
